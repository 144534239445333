import React from "react";
import { Layout } from "../layouts/layout";
import { PostHeader } from "../components/header";
import portrait from "../projects/images/owen.jpg";

const About = (props) => {
  return (
    <article className="post mb4 ph3 backround-white lato">
      <div>
        <PostHeader headertext="About Me" />
        <img
          className="alignleft size-full wp-image-6"
          title="owen"
          src={portrait}
          alt=""
          width="300"
          height="300"
          style={{ paddingLeft: 0 }}
        />

        <p>
          I design and develop products that enable experiences for people that
          defy their expectations and change how they inhabit the world. I love
          tools, using them and making them. I nerd out over boolean logic,
          keyboard interactions, and comboboxes.
        </p>

        <p>
          Currently I work at Salesforce, where I've had the privledge of
          leading the redesign of lists and filtering for the new Lightning UI,
          leading the redesign of Lightning Flow, and working with the UX
          R&amp;D team to define, design, and develop Einstein Designer, a
          machine learning powered tool to automate key design tasks for
          marketers and merchandisers.
        </p>

        <p>
          Previously I studied design strategy and interaction design at the
          Institute of Design. My design career started at Bard College where I
          built interactive sculptures that used vernacular objects to engage
          visitors in novel interactions. Through internships at Doblin and
          organicARCHITECT, I began translating my sculptural talents to design.
          I have extended these skills at the Institute of Design with
          techniques to build empathy with users, rapidly explore concepts, and
          develop a supporting business case.
        </p>

        {/* <p>
                    I am seeking a position in a collaborative environment designing interactions that foster valuable new experiences for clients and users.
                </p> */}

        <p>xO</p>
        <div id="postinfotext" style={{ display: "flex" }}>
          <div style={{ width: "272px" }}>
            {/* <h5>contact me</h5>
                        <span>
                            <a href="http://owenschoppe.com/wp-content/uploads/2011/01/schoppe_owen_s2011.pdf">
                                Download Resume
                            </a>
                        </span> */}
          </div>
        </div>
      </div>
    </article>
  );
};

const Index = (props) => {
  return (
    <Layout>
      <About id={"about_me"} {...props} />
    </Layout>
  );
};

export default Index;
